import React, { useCallback, useContext, useEffect, useState } from "react";
import { Header as HeaderLibrary } from "carynhealth-component-library";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthenticateContext";
import config from "../../config/constants/config";
import routePaths from "../../config/constants/routePaths";
import data from "./data";
import StyleWrapper from "./style";
import { useLocation } from "react-router-dom";
import {
  Link,
  useMediaQuery,
  useTheme,
  IconButton,
  styled,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import titles from "../../config/constants/titles";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import { getMenuData } from "./Sidebar/handler";
import AskCarynLogo from "../../assests/images/uhf/ask-caryn-beta-responsive.png";
import CloseIcon from "@mui/icons-material/Close";
import { StorageHelpers } from "carynhealth-component-library";
import { getOs } from "../RightTopMemberTabs/utility";
import { getNetworkName } from "../Dashboard/Contact-Information/handler";

const headerData = { ...data[config.APP_NAME as keyof typeof data] };
const routes = routePaths[config.APP_NAME as keyof typeof routePaths];
const isUHF = config.APP_NAME === config.BRAND_UHF;
const isIP = config.APP_NAME === config.BRAND_INNOVATION;
const LoginHeaderStyle = styled("div")(({ theme }) => {
  return {
    alignContent: "center",
    textAlign: "center",
    boxShadow:
      "0 2px 4px 0 rgba(0,0,0,.2), 0 1px 10px 0 rgba(0,0,0,.12), 0 4px 5px 0 rgba(0,0,0,.14)",
    height: 56,
    zIndex: 999,
    backgroundColor: isUHF ? "#543379" : "#ffff",
    color: isUHF ? "#ffff" : "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: '"Roboto",sans-serif',
    fontSize: 20,
    fontWeight: 400,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: 0.15,
    marginBottom: 26,
    position: "fixed",
    width: "100%",
  };
});
const Header: React.FC = (props) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const showText = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(600));
  let [pageName, setPagename] = useState<string>("Member Portal");
  const planID: any = getLocalStorage("planId");
  const clientId: any = getLocalStorage("CLIENT_ID");
  const [SidebarIndex, setSidebarIndex] = useState(-1);
  const [sideBarObj, setSideBarObj] = React.useState<any>(null);
  const NETWORK_NAME: any = getLocalStorage("NETWORK_NAME");

  useEffect(() => {
    getPageName();
  }, [location.pathname, SidebarIndex]);

  useEffect(() => {
    // auth.authenticatedInfo.isAuthenticated &&
    displayMenuListData();
  }, [auth.authenticatedInfo.isAuthenticated, planID, NETWORK_NAME]);

  const displayMenuListData = async () => {
    let sortedArray = headerData.sideBarData.listOfSideBar;
    if (isUHF) {
      sortedArray = await getMenuData({
        planId: planID,
        networkName: "uhf",
        clientId: clientId,
        DataArray: headerData.sideBarData.listOfSideBar,
      });
    }
    const nwName: any = await getNetworkName(planID);
    if (isIP && nwName?.provider?.toLowerCase().includes("elite")) {
      sortedArray = sortedArray.filter((x: any) => x.displayType !== "elite");
    }
    headerData.sideBarData.listOfSideBar = sortedArray;
  };

  const getPageName = async () => {
    if (location.pathname !== routes.dashboard && showText) {
      let routekey = await getKeyByValue(routes, location.pathname, "byValue");
      let titleKey = await getKeyByValue(titles, routekey, "byKey");
      let name = "";
      if (titleKey === "myNeeds" && config.APP_NAME === config.BRAND_NETWELL) {
        name = titles.shareRequests;
      } else {
        name = titles[titleKey as keyof typeof titles].toLocaleLowerCase();
      }
      setPagename(name);
    }
    let drawerIndex = headerData?.sideBarData?.listOfSideBar?.find(
      (x: any) => x.navigate.path == location.pathname
    );
    if (
      drawerIndex &&
      drawerIndex.component == null &&
      headerData.sideBarData.selectedItem.selectedIndex != drawerIndex?.index
    ) {
      headerData.sideBarData.selectedItem.selectedIndex = drawerIndex?.index;
    }
  };

  function getKeyByValue(object: any, value: any, type: string) {
    if (type === "byValue") {
      return Object.keys(object).find(
        (key: string | number) => object[key] === value
      );
    }
    return Object.keys(object).find(
      (key: string) => key.toLocaleLowerCase() === value.toLocaleLowerCase()
    );
  }

  const logout = useCallback(() => {
    auth.resetAuthenticateInfo();
    window.location.href = "/";
  }, []);

  const rightIconsCallback = useCallback(
    (data: any) => {
      if (data?.icon?.alt === "Logout") return logout();
    },
    [headerData]
  );

  const innovativeFindAProvider = (text: string) => {
    const NETWORK_NAME = StorageHelpers.getLocalStorage("NETWORK_NAME") || "";
    if (text === "Find a Provider") {
      if (NETWORK_NAME.toLowerCase().includes("elite"))
        return "https://www.firsthealthlbp.com/";
      if (NETWORK_NAME.toLowerCase().includes("optimum"))
        return "https://www.multiplan.com/webcenter/portal/ProviderSearch";
    } else {
      if (text === "Cix Health") {
        return openCixHealth();
      }
      if (text === "Teladoc") {
        return openTeledoc();
      }
    }
    return "";
  };

  const openCixHealth = () => {
    var os = getOs();
    if (os == "iOS") {
      return "https://apps.apple.com/us/app/cix-health/id1477684066";
    }
    if (os == "Android") {
      return "https://play.google.com/store/apps/details?gl=US&hl=en_US&id=com.cixhealth.app";
    }
    return "https://www.cixhealth.com/";
  };

  const openTeledoc = () => {
    var os = getOs();
    if (os == "iOS") {
      return "https://itunes.apple.com/app/apple-store/id656872607?pt=1032497&ct=corporateSite&mt=8";
    }
    if (os == "Android") {
      return "https://play.google.com/store/apps/details?id=com.teladoc.members&referrer=utm_source%3Dgoogle%26utm_medium%3DCorporateSite%26utm_term%3DFooter";
    }
    return "https://www.teladoc.com/";
  };

  const findProvider = (path: string, text: string) => {
    if (config.APP_NAME == config.BRAND_INNOVATION) {
      let url = innovativeFindAProvider(text);
      return url;
    }
    return path;
  };

  const sidebarCallback = useCallback(
    (data: any) => {
      const object: any = headerData.sideBarData.listOfSideBar[data.index];
      setSidebarIndex(-1);
      if (object.text === "Sign Out") {
        setSidebarIndex(data.index);
        setSideBarObj({});
        return logout();
      } else if (object?.navigate?.newTab) {
        setSidebarIndex(data.index);
        setSideBarObj({});
        return window.open(findProvider(object?.navigate?.path, object?.text));
      } else if (object?.component) {
        if (object?.navigate?.path && showText) {
          setSidebarIndex(data.index);
          setSideBarObj({});
          navigate(object?.navigate?.path);
        } else {
          let popupObj = headerData?.sideBarData?.listOfSideBar[data.index];
          setSideBarObj({ ...popupObj });
          setSidebarIndex(data.index);
          console.log(object?.component);
        }
      } else {
        setSidebarIndex(data.index);
        setSideBarObj({});
        navigate(object?.navigate?.path);
      }
    },
    [props]
  );

  function closePopup() {
    setSideBarObj(null);
  }

  if (
    !auth.authenticatedInfo.isAuthenticated &&
    location.pathname !== routes.dashboard &&
    sessionStorage.getItem("USER_ACTIVE_SESSION") !== "true"
  ) {
    if (showText) {
      return (
        <LoginHeaderStyle>
          <div>Member Portal</div>
        </LoginHeaderStyle>
      );
    }
    return null;
  }

  if (!headerData.user) {
    headerData.user = auth.authenticatedInfo.name;
    headerData.rightSectionIconList.rightCallBack = rightIconsCallback;
    headerData.sideBarData.sideBarCallBack = sidebarCallback;
  }

  if (location.pathname === routes.aiChat && showText)
    return (
      <StyleWrapper>
        <div className="header-containers  fixed-top">
          <img src={AskCarynLogo} className="logo" />
          <div className="caption">{titles.AiAssistantTitle}</div>
          <IconButton
            aria-label="close"
            onClick={() => navigate(routes.dashboard)}
          >
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
      </StyleWrapper>
    );

  if (sideBarObj?.component) {
    const Comp = sideBarObj.component;
    sideBarObj.component = React.cloneElement(Comp, {
      onClick: () => closePopup(),
    });
  }

  return (
    <StyleWrapper>
      <HeaderLibrary
        {...headerData}
        variant={
          location.pathname !== routes.dashboard && showText ? "mobile" : "web"
        }
        style={
          location.pathname === routes.myTransaction && isMobileScreen
            ? { position: "fixed" }
            : location.pathname !== routes.dashboard && showText
            ? {
                boxShadow:
                  "0 2px 4px 0 rgb(0 0 0 / 20%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 4px 5px 0 rgb(0 0 0 / 14%)",
              }
            : {}
        }
        MobileViewComponant={() => (
          <div className="mobile_headerNetWell">
            <Link
              style={{ color: "#000", width: "24px", height: "24px" }}
              href="/dashboard"
            >
              <ArrowBackIcon></ArrowBackIcon>
            </Link>
            <div className="mobile_header_titleNetWell">{pageName}</div>
          </div>
        )}
      />
      {sideBarObj?.component && sideBarObj.component}
    </StyleWrapper>
  );
};

export default Header;
